import { useSelector } from "react-redux";
import { Translation } from "features/localisation/localisation.types";
import useTranslate from "features/localisation/useTranslate";
import ImageLazy from "components/image-lazy/image-lazy";

import { selectors } from "./state";
import "./brand-styles.scss";

function BrandLogo() {
    const logoSrc = useSelector(selectors.getBrandLogo);
    const name = useSelector(selectors.getBrandName);
    const hasError = useSelector(selectors.hasError);
    const isCompleted = useSelector(selectors.isComplete);
    const { translateAsString } = useTranslate();

    if (!logoSrc || hasError || !isCompleted) {
        return null;
    }

    return (
        <div className="brand-logo">
            <ImageLazy
                src={logoSrc}
                className="brand-logo--image"
                title={name || ""}
                alt={translateAsString(Translation.BRAND_altText)}
            />
        </div>
    );
}

export default BrandLogo;
